enum MetaTags {
  Description = 'description',
  Robots = 'robots',
};

enum Rel {
  Alternate = 'alternate',
  Canonical = 'canonical',
}

const robotsContent = [
  'noindex,follow',
  'index,follow',
  'noindex,nofollow',
  'index,nofollow',
] as const;

export {
  MetaTags,
  Rel,
  robotsContent,
};
